import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

function App() {
  const [path, setPath] = useState("");
  const location = window.location.pathname;
  const checkDeviceAndRedirect = async () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const pathloc = window.location.pathname;
    const currentPath = pathloc.substring(1);
    console.log("Current Path:", currentPath);
    setPath(currentPath);
    const isAppInstalled =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // Replace 'yourapp://deeplink' with your app's deep link URL
    const appLink = `guruvice://${currentPath}`;

    if (isAppInstalled) {
      window.location.href = appLink;
      setTimeout(() => {
        // If the user is still on the webpage after a delay, the app might not be installed.
        if (/android/i.test(userAgent)) {
          console.log("Device is Android");
          // Perform actions specific to Android
          if (isMobile && window.navigator.standalone) {
            // App is installed, redirect to inner paths
            window.location.href = appLink; // Replace with your inner path
          } else {
            // App is not installed, redirect to the Play Store
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.guruvice"; // Replace with your app's Play Store URL
          }
        } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
          console.log("Device is iOS");
          if (isMobile && window.navigator.standalone) {
            // App is installed, redirect to inner paths
            window.location.href = appLink; // Replace with your inner path
          } else {
            // App is not installed, redirect to the Play Store
            window.location.href = "https://testflight.apple.com/join/X1OcOuJi"; // Replace with your app's Play Store URL
          }
          // Perform actions specific to iOS (iPhone, iPad, iPod)
        } else {
          console.log("Device is neither Android nor iOS");
          window.location.href = "https://guruvice.com";
          // Handle other platforms
        }
      }, 2000); // Adjust the delay as needed
    }
  };
  const checkCurrentPath = async () => {
    const currentPath = window.location.pathname;
    console.log("Current Path:", currentPath);
    setPath(currentPath);
  };
  useEffect(() => {
    checkDeviceAndRedirect();
    // checkCurrentPath();

    // Accessing the pathname from the location object
    const currentPath = location.pathname;

    // Check the current path and perform actions based on it
    if (currentPath === "/home") {
      // Do something if the current path is '/home'
      console.log("You are on the home page");
    } else if (currentPath === "/about") {
      // Do something if the current path is '/about'
      console.log("You are on the about page");
    }
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>welcome to guruvice </p>
      </header>
    </div>
  );
}

export default App;
